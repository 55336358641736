import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import ContentArea from '../../molecules/common/ContentArea';
import TermsOfService from '../../molecules/common/TermsOfService';

import * as styles from './index.module.css';

const TermsOfServiceTemplate = ({ pageContext }) => {
  const { pagePath } = pageContext;

  return (
    <div className="root">
      <SEO path={pagePath} title="利用規約" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <section>
              <h1 className="sr-only">サービス利用規約</h1>
              <ContainerDefault>
                <ContentArea>
                  <TermsOfService />
                </ContentArea>
              </ContainerDefault>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </div>
  );
};

export default TermsOfServiceTemplate;
